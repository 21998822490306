<template>
    <div class="home-carousel-wrapper">
        <vueper-slides class="no-shadow home-carousel" :class="{'mobile': mobileCheck}" :fixed-height="true" :touchable="false" :autoplay="true" :infinite="false">
            <vueper-slide :style="'background-position: center;'" :key="0" :image="require('../../assets/home-en-primeur.jpg')">
                <template #content>
                    <div :style="'background-color: rgba(0,0,0,0.4);height: 100%;'">
                        <div class="container1366 section1-container">
                            <div class="section-mid">
                                <h1 class="section1-title">{{ $t("enprimeur-modal07") }}</h1>
                                <h2 class="section1-subtitle">{{ $t("lp-zoho8") }}</h2>
                                <div class="home-section2-btn-wrapper">
                                    <nuxt-link :to="localePath('/trading-desk/EnPrimeur')" class="home-section1-btn1 ga4-reg-btn">{{ $t("lp-zoho9") }}</nuxt-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </vueper-slide>
            <vueper-slide :style="'background-position: center;'" :key="1" :image="require('../../assets/main_home1.svg')">
                <template #content>
                    <div class="container1366 section1-container">
                        <div class="section1-left">
                            <h1 class="section1-title">{{ $t("new-home1") }}</h1>
                            <h2 class="section1-subtitle">{{ $t("new-home2") }}</h2>
                            <div class="home-section2-btn-wrapper">
                                <nuxt-link :to="localePath(isLogged ? '/trading-desk' : '/register')" class="home-section1-btn1 ga4-reg-btn">{{ $t("new-home3") }}</nuxt-link>
                                <nuxt-link :to="localePath('/how-it-works')" class="home-section1-btn2">{{ $t("show-me-how") }}</nuxt-link>
                            </div>
                        </div>
                        <div class="section1-right" v-if="!mobileCheck"></div>
                    </div>
                </template>
            </vueper-slide>
            <vueper-slide :style="'background-position: center;'" :key="2" :image="require('../../assets/landing/investor/hero-investor-woman.png')">
                <template #content>
                    <div class="container1366 section1-container">
                        <div class="section1-left">
                            <h1 class="section1-title">{{ $t("lp-collections01") }}<div>{{ $t("lp-collections02") }}</div></h1>
                            <h2 class="section1-subtitle">{{ $t("lp-collections28") }}</h2>
                            <div class="home-section2-btn-wrapper">
                                <nuxt-link :to="localePath('/collections')" class="home-section1-btn1 ga4-reg-btn">{{ $t("lp-collections29") }}</nuxt-link>
                            </div>
                        </div>
                        <div class="section1-right" v-if="!mobileCheck"></div>
                    </div>
                </template>
            </vueper-slide>
        </vueper-slides>
    </div>
</template>

<script>
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import {GCommon, GUser} from "@/store/getters";
import { mapGetters } from "vuex";

export default {
    name: "HomeCarousel",
    components: {
        VueperSlides,
        VueperSlide
    },
    computed: {
        ...mapGetters({
            mobileCheck: GCommon.GET_MOBILE_VERSION,
            isLogged: GUser.GET_USER_LOGGED
        })
    }
}
</script>

<style scoped>
.vueperslides--fixed-height {
    height: 480px;
}

.container1366 {
    position: relative;
    max-width: 1366px;
    margin: 0 auto;
    padding: 0 138px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 1;
    height: 100%;
}

.home-section1-btn1 {
    padding: 8px 16px;
    height: 36px;
    background: #ffffff;
    border-radius: 8px;
    color: #004F7F;
    display: inline-block;
    vertical-align: top;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    box-sizing: border-box;
    text-decoration: none;
}
.home-section1-btn2 {
    display: inline-block;
    vertical-align: top;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 8px;
    padding: 8px 16px;
    height: 36px;
    margin-left: 12px;
    box-sizing: border-box;
    text-decoration: none;
}
.section1-left,
.section1-right {
    display: inline-block;
    width: calc((100% - 30px) / 2);
    vertical-align: top;
    height: 100%;
}
.section-mid {
    width: 100%;
    vertical-align: top;
    display: inline-block;
    text-align: center;
}
.section1-left {
    margin-right: 24px;
}
.section1-title {
    margin-top: 94px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    color: #FFFFFF;
    margin-bottom: 16px;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.section1-title div {
    color: #8CD0FA;
}
.section1-subtitle {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    color: #FFFFFF;
    margin-bottom: 32px;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.home-carousel {
    max-width: 100% !important;
    width: 100%;
}

.home-carousel .vueperslide {
    background-size: cover;
    position: relative;
    margin: 0 !important;
}

.home-carousel .vueperslide__title,
.home-carousel .vueperslide__content {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    color: #FFF;
    z-index: 100;
}

.home-carousel .vueperslide__title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    z-index: 100;
}

.home-carousel .vueperslide__content {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 40px;
    z-index: 100;
    max-width: 620px;
}

.home-carousel .vueperslide__button {
    text-decoration: none;
    z-index: 11111;
}

.home-carousel.mobile .vueperslide__title {
    font-size: 22px;
}

.home-carousel.mobile .vueperslide__content {
    font-size: 16px;
    margin-bottom: 40px;
}

.home-carousel .vueperslide__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    min-width: 148px;
    height: 32px;
    background: #E2C477;
    border-radius: 4px;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    color: #FFF;
}

.home-carousel .vueperslides__arrow svg {
    stroke-width: 2;
}

.home-carousel .vueperslides__bullet .default {
    background-color: rgba(204, 204, 204, 1);
    border: none;
    box-shadow: none;
    transition: 0.3s;
    width: 16px;
    height: 16px;
}

.home-carousel .vueperslides__bullet--active .default {
    background-color: rgba(254, 226, 153, 1);
}

.home-carousel .vueperslides__bullet span {
    display: block;
    color: #fff;
    font-size: 10px;
    opacity: 0;
}

@media (max-width: 767px) {
    .section1-container {
        height: 100%;
        padding: 16px;
        text-align: center;
    }
    .section1-left {
        width: 100%;
        margin: 0 auto;
        display: block;
    }
    .section1-left .home-section2-btn-wrapper {
        text-align: center;
    }
    .section1-title {
        font-size: 24px;
        line-height: 26px;
        margin-top: 90px;
    }
    .section1-subtitle {
        font-size: 16px;
        line-height: 20px;
    }
}
</style>

<style>
.home-carousel-wrapper .vueperslides__bullet .default {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    border: 0;
    background-color: rgba(255, 255, 255, 0.24);
    box-shadow: none;
    transition: .4s ease-in-out;
    box-sizing: border-box;
}
.home-carousel-wrapper .vueperslides__bullet.vueperslides__bullet--active .default {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: #E2C477;
}
.home-carousel-wrapper .vueperslides__arrow--prev {
    background: url("../../assets/home/testimonials_left.png") no-repeat center;
    background-size: contain;
    width: 38px;
    height: 36px;
}

.home-carousel-wrapper .vueperslides__arrow--next {
    background: url("../../assets/home/testimonials_right.png") no-repeat center;
    background-size: contain;
    width: 38px;
    height: 36px;
}
@media (max-width: 767px) {
    .home-carousel-wrapper .vueperslides--fixed-height .vueperslides__inner,
    .home-carousel-wrapper .vueperslides--fixed-height .vueperslides__parallax-wrapper,
    .home-carousel-wrapper .vueperslides--fixed-height .vueperslide {
        height: 480px;
        padding-bottom: 0;
    }
    .home-carousel-wrapper .vueperslides__arrow--prev,
    .home-carousel-wrapper .vueperslides__arrow--next {
        top: calc((100% - 38px) / 2);
        width: 30px;
        height: 28px;
    }
}
</style>
